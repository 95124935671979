.item-card {
  background-color: var(--bs-secondary-bg-subtle);
  border-radius: $border-radius;
  height: 80px;

  & dt {
    color: var(--bs-secondary-text-emphasis);
    font-size: 1rem;
  }

  & dd {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0;
  }
}
