.multi-selector-container {
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  z-index: 99999 !important;
  background-color: var(--bs-body-bg);
  padding: 5px;
  border: var(--bs-border-color) solid 1px;
  border-radius: 5px;
  position: absolute;
}

.multi-selector-item {
  cursor: pointer;
  padding: 0.5rem !important;

  &:hover {
    background-color: $nav-pills-link-active-bg;
    color: var(--bs-light);
  }
}

.multi-selector-box {
  background-color: $input-bg;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
}
