.profile-popover {
  .dropdown-item {
    padding: 0;
  }

  .nav-link {
    padding: 0.5rem 1rem;
    transition: none;
  }

  .dropdown-divider {
    margin: 0;
  }
}
