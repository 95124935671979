// The styles in this file can not be modified using Bootstrap variables, surprisingly.

$border-radius: var(--bs-border-radius);

.table {
  // Fix borders not showing on sticky columns. Borders are on table cells anyways.
  border-collapse: separate;
  border-spacing: 0;

  & > :not(caption) > * > *:last-child {
    // Make room for sorter icon in header
    padding-right: 1.4rem;
  }

  & > thead > tr > th,
  & > tfoot > tr > th {
    border-bottom: none;
    color: var(--bs-light);

    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }

  & > thead > tr > th {
    background-color: var(--bs-secondary);
  }

  & > tfoot > tr > th {
    background-color: var(--bs-primary);
  }

  .th-sortable {
    &::after {
      content: "⬍";
      font-weight: 400;
      font-size: 1.5rem;
      display: inline-block;
      line-height: 1;
      margin-right: -1rem;
      width: 1.1rem;
      opacity: 0.6;
      vertical-align: top;
      text-align: right;
      z-index: 0;
    }

    &.th-sortable-asc::after,
    &.th-sortable-desc::after {
      opacity: 1;
    }

    &.th-sortable-asc::after,
    &.th-sortable-desc::after,
    &:hover::after {
      font-size: 1rem;
      line-height: 1.5;
      vertical-align: text-top;
    }

    &.th-sortable-asc::after {
      content: "▲";
    }

    &.th-sortable-desc::after {
      content: "▼";
    }

    &:hover {
      &::after {
        content: "▼";
        opacity: 0.6;
      }

      &.th-sortable-asc::after {
        content: " ";
      }

      &.th-sortable-desc::after {
        content: "▲";
      }
    }

    & > span {
      vertical-align: text-top;
    }
  }
}

.form-table {
  & > tbody > tr > td {
    border: none;
  }

  &.form-table-sm {
    & > tbody > tr > td {
      padding-bottom: $table-cell-padding-y-sm;
      padding-top: $table-cell-padding-y-sm;
    }
  }
}
