.global-search-box-container {
  width: 30%;

  @include media-breakpoint-down(xxl) {
    width: 40%;
  }

  @include media-breakpoint-down(lg) {
    width: 60%;
  }
}
