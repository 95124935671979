.summary-pill {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bs-secondary-bg-subtle);
}

.summary-pill__text > span {
  display: block;
  text-align: end;
  line-height: 1.2;
  width: 238px;
}

.summary-pill__value {
  font-size: 2rem;
  font-weight: bold;
}

.summary-pill__label {
  font-size: 1rem;
  font-weight: normal;
}

.summary-pill_lg .summary-pill__text > span {
  width: 280px;
}

.summary-pill_sm {
  .summary-pill__text > span {
    width: 180px;
  }

  .summary-pill__value {
    font-size: 1.6rem;
  }

  .summary-pill__label {
    font-size: 0.9rem;
  }
}
