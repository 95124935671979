$enable-shadows: true;

$primary: #00518b;
$secondary: #54636d;
$light: #fff;
$body-bg: $light;

$btn-font-weight: 600;

$form-label-font-weight: 600;
$form-label-margin-bottom: 0.25rem;

$input-group-addon-font-weight: 600;

$navbar-dark-color: $light;
$navbar-nav-link-padding-x: 0.75rem;

$nav-link-font-weight: 600;
$nav-pills-border-radius: 0;
$nav-pills-link-active-bg: #004373;
$nav-tabs-border-color: $nav-pills-link-active-bg;
$nav-tabs-border-width: 1px;
$nav-tabs-link-active-bg: $nav-pills-link-active-bg;
$nav-tabs-link-active-color: $light;
$nav-tabs-link-active-border-color: $nav-tabs-link-active-bg;
$nav-tabs-link-hover-border-color: $nav-tabs-link-active-border-color;

$table-border-color: $secondary;
$table-cell-padding-x: 0.8rem;
$table-cell-vertical-align: middle;
$table-group-separator-color: none;
$table-th-font-weight: 700;

$breadcrumb-active-color: #212529;
$breadcrumb-divider-color: $breadcrumb-active-color;
$breadcrumb-divider: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8'><path d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='#{$breadcrumb-divider-color}'/></svg>");

$offcanvas-box-shadow: 0 0 1rem -0.25rem #000;

$position-values: (
  0: 0,
  13: 13px,
  25: 25px,
  50: 50%,
  100: 100%,
);

@import "bootstrap/scss/bootstrap";

@if $enable-dark-mode {
  @include color-mode(dark) {
    body {
      background-color: $black;
    }

    .breadcrumb {
      $breadcrumb-active-color: $body-color-dark;
      $breadcrumb-divider-color-dark: $breadcrumb-active-color;
      --bs-breadcrumb-item-active-color: #{$breadcrumb-active-color};
      --bs-breadcrumb-divider-color: #{$breadcrumb-divider-color-dark};
      --bs-breadcrumb-divider: #{url(escape-svg(
          "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8'><path d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='#{$breadcrumb-divider-color-dark}'/></svg>"
        ))};
    }

    .svg {
      filter: invert(0.9) hue-rotate(180deg);
    }
  }
}

.svg-light {
  filter: brightness(0) invert(1);
}

@import "react-datepicker/dist/react-datepicker.min.css";
@import "react-image-crop/src/ReactCrop.scss";

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefef;
}

dt {
  font-weight: 700;
}

@import "./App";
@import "./Login";
@import "./Nav";
@import "./forms";
@import "./components/IconBox";
@import "./components/ActionButton";
@import "./components/Autocomplete";
@import "./components/SummaryPill";
@import "./components/Table/ColumnSearchBox";
@import "./components/Table/Table";
@import "./components/AllocationBar/AllocationBar";
@import "./components/DataMapping";
@import "./components/GlobalSearchBox";
@import "./components/MultiSelector";
@import "./billing/BillingInfo.scss";
