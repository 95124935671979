.icon-box {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: var(--bs-border-radius);
  background-color: var(--bs-light);

  & > img {
    max-height: 80%;
    height: auto;
    max-width: 80%;
    width: auto;
  }
}
