.navbar {
  background-color: var(--bs-body-bg);
}

@include media-breakpoint-up(lg) {
  $header-height: 65px;

  .sidebar {
    position: sticky;
    top: $header-height;
    bottom: 0;
    height: calc(100vh - $header-height);
    width: 100%;
    overflow-y: auto;
  }

  .sidebar-col {
    position: relative;
    max-width: 250px;
  }
}

.sidebar {
  z-index: 100;
}

@include color-mode(light) {
  .sidebar {
    &,
    .navbar {
      background-color: var(--bs-primary);
    }
  }
}

.sidebar-icon-box {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  background-color: var(--bs-light);
  width: 46px;
  height: 46px;
  margin-right: 1rem;
  border-radius: var(--bs-border-radius);

  & > img {
    max-width: 70%;
    height: auto;
  }
}

.sidebar .navbar .nav-item .nav-link {
  &:hover {
    background-color: var(--bs-nav-pills-link-active-bg);
  }
}

.nav-tabs {
  border-bottom-width: 6px;

  .nav-link {
    margin-right: 0.6rem;
    margin-bottom: 0;
    background-color: var(--bs-light);
    border-color: var(--bs-secondary);
    border-bottom: none;
    color: var(--bs-body-color);

    &:hover {
      background-color: var(--bs-nav-tabs-link-active-bg);
      color: var(--bs-light);
    }
  }
}

@include color-mode(dark) {
  .sidebar {
    background-color: var(--bs-body-bg);
  }

  .nav-tabs {
    .nav-link {
      background-color: var(--bs-dark);

      &:hover {
        background-color: var(--bs-nav-tabs-link-active-bg);
        color: var(--bs-light);
      }

      &.active {
        background-color: var(--bs-nav-tabs-link-active-bg);
        border-color: var(--bs-nav-tabs-link-active-border-color);
      }
    }
  }
}
