.btn-action {
  height: 50px;
  width: 200px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1rem;
  border-radius: var(--bs-border-radius);

  & > span {
    text-align: start;
    line-height: 1.2;
  }

  .icon-box {
    height: 36px;
    width: 36px;
  }
}

.btn-action_secondary_wide {
  width: 260px;
}

.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  padding: 0;
}
