.segment {
  height: 6px;
  transition: 0.2s;
}

.segment:hover {
  box-shadow: 0px 0px 3px #888888;
}

.vertical-bar {
  width: 1px;
  height: 6px;
  margin: 0px auto 3px auto;
}

.pointer {
  width: 5px;
  height: 5px;
  border-radius: 5px;
  margin: auto 5px;
}

.helper-box {
  display: flex;
  font-size: 10px;
}

.helper-item {
  display: flex;
  margin: auto;
}
