.mapping-box-drag-over {
  box-shadow:
    0 0 1px #fff,
    0 0 1px #fff,
    0 0 1px #fff,
    0 0 2px #00a5e6,
    0 0 2px #d7dce1,
    0 0 2px #dee9f9,
    0 0 3px #009be6;
}

.mapping-table-box {
  min-height: 200px;
  max-height: calc(100vh - 325px);
  overflow-y: auto;

  & > .table > tbody > tr:last-child > td {
    border-bottom-width: 0;
  }
}

.draggable-item {
  background-color: var(--bs-secondary-bg-subtle);
}
