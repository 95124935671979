$navbar-height: 65px;

main {
  // Max viewport height minus header and footer heights
  min-height: calc(100vh - $navbar-height - 29px);
}

// Flatten borders on button groups inside of input groups
.input-group {
  > .btn-group {
    &:first-child > .btn:not(:first-child),
    &:not(:last-child) > .btn {
      @include border-end-radius(0);
    }

    &:last-child > .btn:not(:last-child),
    &:not(:first-child) > .btn {
      @include border-start-radius(0);
    }
  }
}

.button-effect {
  transition: 0.15s;
}

.button-effect:hover {
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.1),
    0 2px 3px 0 rgba(0, 0, 0, 0.05);
}

.breadcrumbs-custom {
  font-size: 1.1rem;
  font-weight: 700;
}

.auto-suggestion-header {
  overflow: hidden;
  text-overflow: ellipsis;
}

.auto-suggestion-title {
  overflow: hidden;
  font-size: 10px;
  text-overflow: ellipsis;
}

.notification-container {
  top: $navbar-height !important;
}
