.column-search-item {
  min-width: 250px;
  max-width: 400px;
  flex: 1;
}

.column-search-group {
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 0rem 1rem !important;
}
